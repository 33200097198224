export const faqs = [
  {
    id: 1,
    question:
      "What is the minimum time for booking Denward Entertainment for an event?",
    answer:
      "Ideally, we expect a minimum of 30 days to prepare for an event as the booking process involves consultation and preparation so that we can provide the best service for you.  We may make an exception to the rule if the event does not require a high level of preparation but in general - 30 days is the minimum time.",
  },
  {
    id: 2,
    question:
      "What is the process for booking Denward Entertainment for an event?",
    answer:
      "In general, it will normally start with you, the customer, contacting us via the website, email or social media.  We will reach out to you in order to schedule a quick consultation, after which we'd provide pricing. If you choose to work with us, we will send you a contract and ask you to provide the deposit.  That's it! Once the contract is signed and we've received your deposit, we will work with you to iron out the fine details leading up to the day of your event.",
  },
  {
    id: 3,
    question: "Is the deposit really non-refundable?",
    answer:
      "In general, yes.  However, we will make an exception in the event that Denward Entertainment has to cancel our involvement in your event.  This is our responsibility and you should not be punished for that.  Having said that, we will do everything in our power to avoid cancellations on our end!",
  },
  {
    id: 4,
    question: "Can we provide our own music for an event?",
    answer:
      "Of course! This would often be a requirement for events such as weddings where specific songs must be played.  Our main request is that if you want us to play music that we typically would not play, we would require you to provide enough music to last for the duration of your event.  We can curate the playlist as required once the music has been provided. For example, for a 3 hour event we'd require roughly 20-25 songs an hour (assuming an average of 3 minutes per track) which is about 75 tracks.",
  },
  {
    id: 5,
    question: "Can we provide our own equipment for the event?",
    answer:
      "We are open to flexible arrangements. However, we'd need to know the equipment that you would be providing during the consultation.",
  },
  {
    id: 6,
    question: "Can we provide our own MC?",
    answer:
      "If all you need is for us to provide the music and audio equipment, that is not a problem! We will coordinate with you to ensure that the event proceeds as expected with your MC of choice.",
  },
  {
    id: 7,
    question: "What other services does Denward Entertainment offer?",
    answer:
      "Denward Entertainment can provide graphic or motion content for you event such as a promotional video or flyer.  We can also provide a custom music mix for your event - e.g. a custom 30 minute Latin music mix for your awesome Salsa Night event.  Denward Entertainment can also create awesome static websites for your organization.  We will work with you in order to make your web vision come true!",
  },
];
