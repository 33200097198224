import { FaFacebookSquare } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { MdOutlineEmail } from "react-icons/md";
import "../css/SocialMediaItem.css";

const SocialMediaItem = ({ socialType, description, isMobile, onClick }) => {
  const icon = getSocialMediaIcon(socialType);

  return getSocialMediaItem();

  function getSocialMediaIcon(socialType) {
    switch (socialType) {
      case "insta":
        return <GrInstagram className={isMobile ? "mobile-icon" : ""} />;
      case "fb":
        return <FaFacebookSquare className={isMobile ? "mobile-icon" : ""} />;
      default:
        return <MdOutlineEmail className={isMobile ? "mobile-icon" : ""} />;
    }
  }

  function getSocialMediaItem() {
    if (isMobile) {
      return (
        <div className="social-media" onClick={onClick}>
          {icon}
        </div>
      );
    }

    return (
      <div className="social-media" onClick={onClick}>
        {icon}&nbsp;&nbsp;{description}
      </div>
    );
  }
};

export default SocialMediaItem;
