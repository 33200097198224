import "../css/FAQ.css";

const FAQItem = ({ question, answer }) => {
  return (
    <div className="faq-item-container">
      <div className="faq-item-title">
        <h3>{question}</h3>
      </div>
      <p>{answer}</p>
    </div>
  );
};

export default FAQItem;
