import { motion } from "framer-motion";
import BackButton from "../components/BackButton";
import "../css/FAQ.css";
import FAQItem from "../components/FAQItem";
import { faqs } from "../assets/faqs";
import { Helmet } from "react-helmet-async";

const FAQ = ({ backHandler }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="faqs-container"
    >
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Frequently Asked Questions</title>
        <meta name="description" content="Frequently Asked Questions" />
        <meta name="author" content="Denward Entertainment" />
        <meta
          name="keywords"
          content="Music, Entertainment, DJ Services, DJ, Weddings, Corporate Events, Kitchener, Cambridge, Waterloo, Guelph, KW, FAQ"
        />
      </Helmet>
      <div className="faqs-content">
        <BackButton backHandler={backHandler} />
        {faqs.map((faq) => (
          <FAQItem key={faq.id} question={faq.question} answer={faq.answer} />
        ))}
        <BackButton backHandler={backHandler} />
      </div>
    </motion.div>
  );
};

export default FAQ;
