import "../css/Booking.css";
import { motion } from "framer-motion";
import BackButton from "../components/BackButton";
import DatePicker from "react-datepicker";
import { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Helmet } from "react-helmet-async";

const Booking = ({ backHandler }) => {
  const options = [
    "Corporate Event",
    "Wedding",
    "Private Event",
    "Birthday Party",
    "Fashion Show",
    "Dance Party",
    "Other",
  ];
  const [selectedDate, setSelectedDate] = useState(new Date());

  const currentDate = new Date();
  let month = currentDate.getUTCMonth() + 1; //months from 1-12
  let day = currentDate.getUTCDate();
  let year = currentDate.getUTCFullYear();
  const minDate = new Date(year + "-" + month + "-" + day);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="booking-container"
    >
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Booking</title>
        <meta name="description" content="How to book our services" />
        <meta name="author" content="Denward Entertainment" />
        <meta
          name="keywords"
          content="Music, Entertainment, DJ Services, DJ, Weddings, Corporate Events, Kitchener, Cambridge, Waterloo, Guelph, KW, Bookings, Equipment"
        />
      </Helmet>
      <BackButton backHandler={backHandler} />
      <div className="booking-content">
        <h1>Contact Us</h1>
        <div className="booking-form-container">
          <form
            id="fs-frm"
            name="simple-contact-form"
            acceptCharset="utf-8"
            action="https://formspree.io/f/xnqyyadj"
            method="post"
          >
            <fieldset id="fs-frm-inputs">
              <label htmlFor="full-name">Full Name *</label>
              <input
                type="text"
                name="name"
                id="full-name"
                placeholder="First and Last"
                required
              />
              <label htmlFor="number">Phone Number *</label>
              <input
                type="text"
                name="number"
                id="number"
                placeholder="e.g (519) 332-1111"
                required
              />
              <label htmlFor="email-address">Email Address *</label>
              <input
                type="email"
                name="_replyto"
                id="email-address"
                placeholder="email@domain.tld"
                required
              />
              <label htmlFor="date">Event Date</label>
              <DatePicker
                name="date"
                id="datepicker"
                minDate={minDate}
                selected={selectedDate}
                onChange={handleDateChange}
                dateFormat="MM/dd/yyyy"
                required
              />
              <label htmlFor="event-type">Event Type</label>
              <select id="event" name="event-type" required>
                <option value="">Select...</option>
                {options.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
              <label htmlFor="other">Other Event Description</label>
              <input
                type="other"
                name="other"
                id="other-event"
                placeholder="If you selected 'Other' - describe the event"
              />
              <label htmlFor="message">Message</label>
              <textarea
                rows="5"
                name="message"
                id="message"
                placeholder="Let us know any additional information"
                style={{ resize: "none" }}
              ></textarea>
              <input
                type="hidden"
                name="_subject"
                id="email-subject"
                value="Contact Form Submission"
              />
            </fieldset>
            <input id="submit-button" type="submit" value="Submit" />
          </form>
        </div>
      </div>
      <BackButton backHandler={backHandler} />
    </motion.div>
  );
};

export default Booking;
