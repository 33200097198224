import { MdOutlineArrowBack } from "react-icons/md";
import "../css/BackButton.css";

const BackButton = ({ backHandler }) => {
  return (
    <div className="back-button" onClick={backHandler}>
      <MdOutlineArrowBack />
      &nbsp;&nbsp;Back To Main Menu
    </div>
  );
};

export default BackButton;
