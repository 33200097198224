import "../css/Service.css";
import { motion } from "framer-motion";
import BackButton from "../components/BackButton";
import { Helmet } from "react-helmet-async";

const Service = ({ backHandler }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="service-container"
    >
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Service</title>
        <meta name="description" content="Service offerings" />
        <meta name="author" content="Denward Entertainment" />
        <meta
          name="keywords"
          content="Music, Entertainment, DJ Services, DJ, Weddings, Corporate Events, Kitchener, Cambridge, Waterloo, Guelph, KW"
        />
      </Helmet>
      <div>
        <BackButton backHandler={backHandler} />
      </div>
      <div className="services-content">
        <h1>Services Offered</h1>
        <span className="description-span">
          At Denward Entertainment, our aim is to provide a custom experience
          that meets the needs of our clients. Our basic package provides all of
          the required equipment for basic audio services at your event. Details
          of the basic package can be found below, alongside additional
          equipment or services that are available to you, should you require
          them.
        </span>
        <div className="services-content-columns">
          <div className="services-content-column-header header-l">
            <h3>Basic Service *</h3>
          </div>
          <div className="services-content-column-header header-l">
            <h3>Additional Services **</h3>
          </div>
        </div>
        <div className="services-content-columns services-l">
          <div className="services-content-column">
            <ul>
              <li>Free Consultation (Determining Requirements)</li>
              <li>2 x Powered Speakers</li>
              <li>2 x Wireless Mics (with receiver)</li>
              <li>1 x Mixing Board</li>
              <li>1 x DJ Controller</li>
            </ul>
            <span className="additional-info">
              * Basic service charges are based on an hourly rate + $150
              setup/breakdown fee + $350 for each additional hour over the
              agreed upon allotted time + HST. Weddings will be charged a flat
              fee + HST
            </span>
          </div>
          <div className="services-content-column">
            <ul>
              <li>Smoke Machine</li>
              <li>Lighting</li>
              <li>Subwoofer (recommendation for dance events)</li>
              <li>MC Services</li>
              <li>Web/Graphic Design Work (Digital or Print Media) ***</li>
              <li>Custom Music Mixes ***</li>
              <li>Custom Video Marketing ***</li>
            </ul>
            <p className="additional-info">
              ** Additional service charges will be charged at a flat rate for
              each service required.
            </p>
            <p className="additional-info">
              *** Custom services will accrue an hourly rate which will be
              charged at the completion of the custom service work.
            </p>
          </div>
        </div>
        <div className="services-content-columns services-sm">
          <div className="services-content-column">
            <div className="services-content-column-header">
              <h3>Basic Service *</h3>
            </div>
            <ul>
              <li>Free Consultation (Determining Requirements)</li>
              <li>2 x Powered Speakers</li>
              <li>2 x Wireless Mics (with receiver)</li>
              <li>1 x Mixing Board</li>
              <li>1 x DJ Controller</li>
            </ul>
            <span className="additional-info">
              * Basic service charges are based on an hourly rate + $150
              setup/breakdown fee + $350 for each additional hour over the
              agreed upon allotted time + HST. Weddings will be charged a flat
              rate + HST
            </span>
          </div>
          <div className="services-content-column">
            <div className="services-content-column-header">
              <h3>Additional Services **</h3>
            </div>
            <ul>
              <li>Smoke Machine</li>
              <li>Lighting</li>
              <li>Subwoofer (recommendation for dance events)</li>
              <li>MC Services</li>
              <li>Web/Graphic Design Work (Digital or Print Media) ***</li>
              <li>Custom Music Mixes ***</li>
              <li>Custom Video Marketing ***</li>
            </ul>
            <p className="additional-info">
              ** Additional service charges will be charged at a flat rate for
              each service required.
            </p>
            <p className="additional-info">
              *** Custom services will accrue an hourly rate which will be
              charged at the completion of the custom service work.
            </p>
          </div>
        </div>
        <h1>Bookings</h1>
        <span className="description-span">
          Confirmed bookings will require a 50% non-refundable deposit. In the
          extremely rare event that we have to cancel our involvment in your
          event, a full refund will be given, however, we will do everything
          within our power to ensure that our involvement in your event moves
          forward as planned
        </span>
        <div>
          <BackButton backHandler={backHandler} />
        </div>
      </div>
    </motion.div>
  );
};

export default Service;
