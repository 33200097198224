import "../css/Home.css";
import SocialMediaItem from "../components/SocialMediaItem";
import { useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const Home = () => {
  const navigate = useNavigate();

  function goHome() {
    navigate("/");
  }

  function handleOpenInstagram() {
    window.open("https://www.instagram.com/denwardentertainment/", "_blank");
  }

  function handleOpenFacebook() {
    window.open(
      "https://www.facebook.com/people/Denward-Entertainment/61554909994668/",
      "_blank"
    );
  }

  function handleOpenEmail() {
    window.open("mailto:denwardentertainment@gmail.com", "_blank");
  }

  return (
    <>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Home</title>
        <meta name="description" content="Denward Entertainment Landing Page" />
        <meta name="author" content="Denward Entertainment" />
        <meta
          name="keywords"
          content="Music, Entertainment, DJ Services, DJ, Weddings, Corporate Events, Kitchener, Cambridge, Waterloo, Guelph, KW"
        />
      </Helmet>
      <div className="container">
        <div className="header header-l" onClick={goHome}></div>
        <div className="header header-sm" onClick={goHome}></div>
        <div className="footer-l">
          <SocialMediaItem
            socialType="insta"
            description="@DENWARDENT"
            isMobile={false}
            onClick={handleOpenInstagram}
          />
          <SocialMediaItem
            socialType="fb"
            description="DENWARD ENTERTAINMENT"
            isMobile={false}
            onClick={handleOpenFacebook}
          />
          <SocialMediaItem
            socialType="email"
            description="DENWARDENT@GMAIL.COM"
            isMobile={false}
            onClick={handleOpenEmail}
          />
        </div>
        <div className="footer-sm">
          <SocialMediaItem
            socialType="insta"
            description="@DENWARDENT"
            isMobile={true}
            onClick={handleOpenInstagram}
          />
          <SocialMediaItem
            socialType="fb"
            description="DENWARD ENTERTAINMENT"
            isMobile={true}
            onClick={handleOpenFacebook}
          />
          <SocialMediaItem
            socialType="email"
            description="DENWARDENT@GMAIL.COM"
            isMobile={true}
            onClick={handleOpenEmail}
          />
        </div>
        <div className="content">
          <Outlet />
        </div>
      </div>
    </>
  );
};

export default Home;
