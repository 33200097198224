import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import Service from "./pages/Service";
import Services from "./pages/Services";
import AboutUs from "./pages/AboutUs";
import FAQ from "./pages/FAQ";
import Booking from "./pages/Booking";
import { useLocation } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { useNavigate } from "react-router-dom";

const PageRoutes = () => {
  const location = useLocation();
  const navigate = useNavigate();

  function backHandler() {
    navigate(-1);
  }

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route exact path="/" element={<Navigate to="/services" replace />} />

        <Route path="/" element={<Home />}>
          <Route index element={<Services />} />
          <Route path="services" element={<Services />} />
          <Route
            path="service"
            element={<Service backHandler={backHandler} />}
          />
          <Route
            path="aboutus"
            element={<AboutUs backHandler={backHandler} />}
          />
          <Route path="faq" element={<FAQ backHandler={backHandler} />} />
          <Route
            path="booking"
            element={<Booking backHandler={backHandler} />}
          />
        </Route>
      </Routes>
    </AnimatePresence>
  );
};
export default PageRoutes;
