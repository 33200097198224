import "../css/Services.css";
import { useNavigate } from "react-router-dom";
import ServiceIcon from "../components/ServiceIcon";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet-async";

const Services = () => {
  const navigate = useNavigate();

  function navigateHandler(path) {
    navigate(path);
  }

  return (
    <motion.div
      className="services-container"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Services</title>
        <meta name="description" content="Main Navigation Page" />
        <meta name="author" content="Denward Entertainment" />
        <meta
          name="keywords"
          content="Music, Entertainment, DJ Services, DJ, Weddings, Corporate Events, Kitchener, Cambridge, Waterloo, Guelph, KW"
        />
      </Helmet>
      <div className="services-row">
        <ServiceIcon
          service="Services"
          path="service"
          handler={navigateHandler}
        />
        <ServiceIcon
          service="Bookings"
          path="booking"
          handler={navigateHandler}
        />
      </div>
      <div className="services-row">
        <ServiceIcon
          service="About Us"
          path="aboutus"
          handler={navigateHandler}
        />
        <ServiceIcon service="FAQ" path="faq" handler={navigateHandler} />
      </div>
    </motion.div>
  );
};

export default Services;
