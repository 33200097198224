import "../css/Services.css";

const ServiceIcon = ({ path, service, handler }) => {
  return (
    <div className="services-icon" onClick={() => handler(path)}>
      <p>{service}</p>
    </div>
  );
};

export default ServiceIcon;
