import "../css/AboutUs.css";
import { motion } from "framer-motion";
import BackButton from "../components/BackButton";
import { Helmet } from "react-helmet-async";

const AboutUs = ({ backHandler }) => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="about-us-container"
    >
      <Helmet>
        <meta charSet="UTF-8" />
        <title>About Us</title>
        <meta
          name="description"
          content="Learn more about Denward Entertainment"
        />
        <meta name="author" content="Denward Entertainment" />
        <meta
          name="keywords"
          content="Music, Entertainment, DJ Services, DJ, Weddings, Corporate Events, Kitchener, Cambridge, Waterloo, Guelph, KW"
        />
      </Helmet>
      <div>
        <BackButton backHandler={backHandler} />
      </div>
      <div className="about-us-image"></div>
      <div className="about-us-content">
        Denward Entertainment is jointly owned and operated by Dennis "DJ Riddy"
        Hall and Edward "Eddie Go Boom" Richardson. Raised from humble
        beginnings on the tiny island of Bermuda, both of us moved to Canada to
        pursue higher education in Computer Science but during this time also
        cultivated our love for the music and entertainment industry. We bring a
        wealth of experience in music production and performance, event planning
        and event marketing. In addition, we are also highly trained technology
        professionals with proven skills in web development and graphic design.
        We've provided our services for many different types of events including
        weddings, corporate events, dance parties and much more. Our goal is to
        provide you with an awesome entertainment experience and service with a
        smile! We look forward to hearing from you.
      </div>
      <div className="about-us-content">
        <BackButton backHandler={backHandler} />
      </div>
    </motion.div>
  );
};

export default AboutUs;
